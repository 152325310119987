import {
  type ClientLoaderFunction,
  ClientLoaderFunctionArgs
} from '@remix-run/react';

import storage from '~/lib/pwa/storage';

export function defineClientLoader<T extends ClientLoaderFunction>(
  loader: T,
  options?: { hydrate: boolean }
) {
  const clientLoader = function proxy(args: ClientLoaderFunctionArgs) {
    return loader({
      ...args,
      context: {
        async cache(serverLoader, { strategy, key, adapter = storage.route }) {
          const existingData = await adapter
            .getItem(key)
            .catch(() => undefined);

          if (strategy === 'normal' && existingData) {
            return {
              ...existingData,
              meta: {
                serverData: existingData,
                deferredServerData: undefined,
                key
              }
            };
          }

          const data = existingData ? existingData : await serverLoader();
          if (data instanceof Response) {
            throw data;
          }

          if (existingData !== data) {
            await adapter.setItem(key, data);
          }

          const deferredServerData = existingData ? serverLoader() : undefined;

          return {
            ...(data ?? existingData),
            meta: {
              serverData: data ?? existingData,
              deferredServerData,
              key
            }
          };
        }
      }
    });
  };

  // TODO: Invert default hydrate behaviour to be true?
  // Is it necessary for the cache?
  clientLoader.hydrate = options?.hydrate ?? true;

  return clientLoader as unknown as T;
}
